.site-header {
  background-color: $site-header-background-color;
  color: $white;
  line-height: 3em;

  .dropdown-menu {
    z-index: 2000;
  }

  .header-logo {
    height: 4rem;
    width: 80px;
    margin-left: 100px;
    background-image: $header-logo-image;
    background-size: cover;
    background-position: right;
    float: right;
  }
}
