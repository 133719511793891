$blue-100: #0d4684;
$gray-100: #f2f2f2;

$aqua: #14d3c9;
$red: #9e0505;
$orange: #fe5000;
$green: #009900;
$yellow: #ffc926;
$navy: #060f4f;

$primary: $navy;
$warning: $orange;

$approved: $green;
$rejected: $red;
