.menu-toggle {
  color: inherit;
  padding: 0;
  text-decoration: none !important;

  &:hover {
    color: inherit;
  }
}

.menu {
  padding: 2em;
  line-height: normal;
  border: 1px solid;

  .menu-logo {
    height: 4rem;
    width: 200px;
    background-image: $menu-logo-image;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 1.5rem;
  }

  .list-heading {
    font-size: 1rem;
  }

  ul {
    margin-bottom: 2rem;
    &.titled {
      li {
        border-bottom: 1px solid #ddd;
        padding-left: 1.5rem;
      }
    }
  }

  .arrow {
    &::after {
      content: '>';
      float: right;
    }
  }
}

.logo {
  width: 200px;
  margin-bottom: 2em;
}
