.pointer {
  cursor: pointer;
}

.primary-link {
  color: lighten($primary, 40%);
}

hr {
  border-top-color: $primary;
  border-top-width: 1px;
}

.btn-sm {
  width: $button-width;
}

.approval-checkblock {
  border: 1px solid gray;

  &.approve {
    color: $approved;
    background-color: white;

    &.selected {
      color: white;
      background-color: $approved;
    }
  }

  &.reject {
    color: $rejected;
    background-color: white;

    &.selected {
      color: white;
      background-color: $rejected;
    }
  }

  &.disabled {
    color: #6c757d;
    background-color: #f2f2f2;
  }
}

.approved {
  color: $approved;
}

.bg-approved {
  background-color: $approved;
}

.rejected {
  color: $rejected;
}

.bg-rejected {
  background-color: $rejected;
}

.bg-no-netsuite-data {
  background-color: #d1e8ff;
}

.bg-unexpected-inactive {
  background-color: yellow;
}

.no-items {
  color: $gray-300;
}

svg {
  &.disabled {
    color: $gray-300;
  }
}
