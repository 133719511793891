@import 'assets/base';

.modal-alert {
  .modal-content {
    padding: 1rem;

    .modal-header {
      padding: 0;

      .modal-title {
        font-size: 1rem;
        color: $white;

        .modal-icon-wrapper {
          padding: 0.5rem;
        }

        .modal-title-text {
          padding: 0.5rem;
        }
      }
    }

    .modal-body {
      label {
        font-weight: bold;
      }

      ol,
      ul {
        padding-inline-start: 1rem;
      }
    }

    .modal-footer {
      border-top: none;
      padding: 0;

      .btn {
        min-width: 6rem;
      }

      .btn-link {
        font-weight: bolder;
        margin: 0;
        padding: 0;
        line-height: 1.2rem;

        svg {
          font-size: 1.3rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &.modal-primary {
    .modal-header {
      background-color: $primary;

      .modal-icon-wrapper {
        background-color: lighten($primary, 10);
      }
    }

    .modal-body {
      label {
        color: darken($primary, 10);
      }
    }
  }

  &.modal-success {
    .modal-header {
      background-color: $success;

      .modal-icon-wrapper {
        background-color: lighten($success, 10);
      }
    }

    .modal-body {
      label {
        color: darken($success, 10);
      }
    }
  }

  &.modal-danger {
    .modal-header {
      background-color: $danger;

      .modal-icon-wrapper {
        background-color: lighten($danger, 10);
      }
    }

    .modal-body {
      label {
        color: $danger;
      }
    }
  }

  &.modal-warning {
    .modal-header {
      background-color: $warning;

      .modal-icon-wrapper {
        background-color: lighten($warning, 10);
      }
    }

    .modal-body {
      label {
        color: $warning;
      }
    }
  }
}
