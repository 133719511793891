.select-dropdown-menu {
  border: 1px solid grey;

  a.dropdown-item {
    min-height: 1.5rem;
  }
}

.disabled-dropdown {
  background-color: #e0e0e0;
  padding: 6px 12px 6px 12px;
}
