button.btn.btn-lg {
  width: $button-width;
  font-size: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;

  &.multiline {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1rem;
  }
}
