.comments-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          margin-left: 1em;
        }
      }
    }
  }
}

.comments-card {
  h1 {
    color: $primary;
  }
}
.comments-wrapper {
  .comment-input-wrapper {
    margin-bottom: 2rem;

    .editor-wrapper {
      margin-bottom: 1em;
    }

    .comment-input {
      resize: none;
      border: 1px solid;
    }

    .fr-toolbar.fr-top,
    .second-toolbar {
      border-radius: unset;
      -moz-border-radius: unset;
      -webkit-border-radius: unset;
    }

    .action-buttons {
      button:first-child {
        margin-right: 1em;
      }
    }
  }

  .comment-content-wrapper {
    margin-bottom: 1em;
    font-size: small;

    .avatar-wrapper {
      float: left;
    }

    .comment-detail-wrapper {
      overflow: hidden;
      padding-top: 0.3rem;

      .comment-heading {
        font-size: small;
        margin-left: 1em;

        .comment-posted-by-name {
          font-weight: bold;
        }

        .comment-posted-date {
          color: $primary;
        }

        .comment-edited-date {
          color: $primary;
          margin-left: 0.5em;
          cursor: help;
        }
      }

      .comment-display {
        font-size: small;
        white-space: pre-line;
        margin-left: 1em;
      }

      .comment-actions-list {
        list-style-type: none;
        margin-bottom: 2em;
        margin-top: 0.25em;
        padding-inline-start: 1em;

        .comment-action-list-item {
          float: left;
          margin-right: 1em;
          cursor: pointer;
          text-transform: uppercase;
          color: $primary;
          font-weight: bold;
        }
      }

      .comment-input-wrapper {
        margin-left: 1em;
      }

      .comment-replies-wrapper {
        margin-left: 1em;

        .show-hide-replies-link {
          cursor: pointer;
          color: $primary;
          font-weight: bold;
          margin-bottom: 0.5em;

          svg {
            margin-right: 1.75em;
          }
        }

        .comment-content-wrapper {
          margin-left: 2em;
        }
      }
    }
  }
}
