/**
  * Attention: Any variables you add here should end with !default to permit them to be overridden by specific themes
  */

$body-color: $navy;
$site-header-background-color: $navy !default;
$header-logo-image: url('/assets/images/KBS_Logo_White_Final-01.png');
$menu-logo-image: url('/assets/images/KBS_Logo_Dark-Blue_Final.png');
$banner-image: url('/assets/images/KBS_AR_banner.jpg');
//$login-background-image: url("/assets/themes/${theme}/images/login-background.png");

$page-header-background: unset !default;

//$btn-border-width: 0;
//$btn-box-shadow: none;

/*

  $input-btn-focus-box-shadow: none;
  */

$enable-rounded: false;
$enable-shadows: false;
$border-width: 1px;
$hr-border-width: 1px;
$table-border-width: 1px;
$input-border-width: 0;

$grid-gutter-width: 1rem;
$button-width: 11.075rem;
