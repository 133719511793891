.page-header {
  height: 80px;
  background: $primary $banner-image no-repeat;
  color: $white;
  font-size: 1.5rem;
  line-height: 5rem;

  .page-title {
    font-size: 1.75rem;
  }

  .app-title {
    font-weight: bolder;
  }

  .header-logo {
    display: inline-block;
    width: 5rem;
    height: 4rem;
    margin-left: 1rem;
    background-image: $header-logo-image;
    background-size: cover;
    background-repeat: no-repeat;
    vertical-align: middle;
  }
}
