.swiss-army-select.form-control {
  // Bootstrap calculates the height of a form-control and this causes the react-select component to grow
  // outside of the form-control container.  We unset the height here so that the from-control will grow
  // vertically and not be stuck at the calculated height.
  height: unset;

  > div {
    // the control container
    border-radius: $border-radius;
    border-width: $border-width;
    box-shadow: none;
    background-color: white; // force a white background even when disabled so that it looks okay with enabled controls

    &:focus {
      //border-color: unset;
    }

    &:hover {
      border-color: unset;
    }

    max-width: inherit;

    > div:nth-child(1) {
      // value container
      div {
        // chips
        div:first-child {
          // chip text
          //max-width: 10em; // limit chip size so one fits in a single GridForm column control
        }
      }
    }

    > div:nth-child(2) {
      // indicators container
      padding-right: 8px;
      > div {
        padding-left: 0;
        padding-right: 0;
      }
      > div:nth-child(2) {
        //border: 1px solid red;
      }
    }
  }

  > div:nth-child(1) {
    // dropdown menu
    margin-top: 0em;
    background-color: white;
    box-shadow: 4px 4px 6px 0px #a0a0a0;
  }

  div {
    // general text
    color: black;

    > svg {
      // indicators
      font-size: 1em;
      color: #878787;
    }
  }
}
