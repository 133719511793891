.grid-section-header-row {
  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2rem;
  }

  h2 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 2rem;
  }

  h3 {
    font-size: 1rem;
    font-weight: bold;
    line-height: 2rem;
  }

  h4 {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 2rem;
  }
}

hr {
  border-top-color: $aqua;
  border-top-width: 1px;
  margin-top: 0;
  margin-bottom: 1rem;
}
